import React, {useEffect, useMemo, useState} from "react"
import { Link } from "@StarberryUtils";
import {Container, Row,Col} from 'react-bootstrap'

import HTMLReactParser from 'html-react-parser';
import PlayVideo from "../../Play/PlayVideo";
import "./VideoArea.scss"
import { AreaGuideDetails } from "../../../queries/common_use_query"
import { ImageModule, SourceBlock } from '../../../modules/Image_Module';
import {youtube_parser} from "../../common/utils";
import { removeDataAttributes } from "../../../comQueryStructure";
// styles

// markup
const VideoArea = ( props ) => {
  const [isPlay,setPlay] = useState(false);
  const [area_guide,setAreaGuide] = useState('');
  // 
  let path_name = (props.property_details_area_guide).split(', ');
  let comm_district = path_name[path_name.length-4];
  let community = path_name[path_name.length-3];
 
  let propData = props.property_details_area_guide_data;
  let area = propData.area
  if(propData.address?.area){
    area = propData.address?.area
  }
  const {loading, error, data} = AreaGuideDetails(area, area);
  const areaguidedata = useMemo(() => removeDataAttributes(data?.communityDistricts), [data])
  const communitiesData = removeDataAttributes(data?.communities)

  useEffect(()=>{
    areaguidedata && setAreaGuide(areaguidedata.length > 0 ? areaguidedata[0] : communitiesData && communitiesData.length > 0 ? communitiesData[0] : '');
  },[areaguidedata])

  
  var youtube_id = area_guide && area_guide.Embed_Video_Url ? youtube_parser(area_guide.Embed_Video_Url) : '';
  //var view_url = data && areaguidedata.length > 0 ? areaguidedata[0].community.URL+'/'+area_guide.URL : area_guide && area_guide.URL;
  var view_url = area_guide && area_guide.URL;

  if(areaguidedata && areaguidedata.length > 0){
     if(areaguidedata[0].community?.URL && area_guide.URL){
       view_url = areaguidedata[0].community?.URL+'/'+area_guide.URL
}
}

  //console.log("area_guide", area_guide);
  return (
  area_guide &&
  <React.Fragment>
    <div className="video-area-block"> 
      <Container>
        <Row>
          <Col xl={6} className="d-flex align-items-center">
             <div className="animated"> 
              <div className="video-area-img img-zoom">
                  <picture>
                    <ImageModule classNames={"bg-img"} ImageSrc={area_guide.Tile_Image} />
                    {
                      youtube_id && 
                      <strong className="play-btn-icon" onClick = { e => {setPlay(true)}}>
                        <i className="icon-play-btn"></i>
                      </strong>
                    }
                    
                  </picture>
                  {/* <picture className="">
                      <source media="(min-width:992px)" srcSet={property_details_similar_property_id}/>
                      <source media="(min-width:768px)" srcSet={property_details_similar_property_id}/>
                      <img src={area_guide.Tile_Image.url} alt=""/>
                      <strong className="play-btn-icon" onClick = { e => {setPlay(true)}}>
                          <i className="icon-play-btn"></i>
                    </strong>
                  </picture> */}
              </div>
             </div>
          </Col>
          <Col xl={5} className="ml-lg-auto">
               <div className="animated"> 
                <div className="video-area-content">
                  {/* <span className="area-name">{area_guide.Name} Area Guide</span> */}
                  <h2>{area_guide.Name}</h2>
                  
                  <p>{HTMLReactParser(area_guide.Content)}</p>
                  <Link className="btn btn-primary" to={'/about/our-communities/'+view_url}><span>View Community</span></Link>
                </div>
               </div>
          </Col>
        </Row>
      </Container>
      <PlayVideo
        isOpen = { isPlay } 
        isCloseFunction = { setPlay }
        videoId={youtube_id}
        isAutoPlay={1}
      />
    </div>
  </React.Fragment>
  )
}

export default VideoArea