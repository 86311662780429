import * as React from "react"
import ScrollAnimation from 'react-animate-on-scroll';
import "./PropertyFacts.scss"
// styles

// markup
const PropertyFacts = (props) => { 

const convertDate = (date) =>{
  const d = new Date(date)
    var formatDate = d.toLocaleDateString("en-UK", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
    return formatDate;
  }

  return (
    props.property_details_facts_data.propertyID &&
    <React.Fragment>
      <div className="property-facts-section">
        <div className="animated">
          <div className="property-facts-title border-line">
            <h2>Property Facts</h2>
          </div>
          <div className="project-fact-list">
            <ul>
              {props.property_details_facts_data.propertyID &&
                <li>
                  <strong>Property ID</strong>
                  <span>{props.property_details_facts_data.propertyID}</span>
                </li>
              }
              {props.property_details_facts_data.size &&
                <li>
                  <strong>Property Size</strong>
                  <span>{props.property_details_facts_data.size} Sqm</span>
                </li>
              }

              {props.property_details_facts_data.furnished &&
                <li>
                  <strong>Furnished</strong>
                  <span>{props.property_details_facts_data.furnished}</span>
                </li>
              }

              
              {props.property_details_facts_data.facts && Object.keys(props.property_details_facts_data.facts).length > 0 &&
                <>
                  {props.property_details_facts_data.facts.monthly_utility_bills &&
                    <li>
                      <strong>Monthly Utility Bills</strong>
                      <span>{props.property_details_facts_data.facts.monthly_utility_bills}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.overall_days_onmarket &&
                    <li>
                      <strong>Overall Days On Market</strong>
                      <span>{props.property_details_facts_data.facts.overall_days_onmarket}</span>
                    </li>

                  }
                  {props.property_details_facts_data.facts.title_deed &&
                    <li>
                      <strong>Title Deed</strong>
                      <span>{props.property_details_facts_data.facts.title_deed}</span>
                    </li>

                  }
                  {props.property_details_facts_data.facts.price_trend &&
                    <li>
                      <strong>Price Trend</strong>
                      <span>{props.property_details_facts_data.facts.price_trend}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.utility_transfer_fee &&
                    <li>
                      <strong>Utility Transfer Fee</strong>
                      <span>{props.property_details_facts_data.facts.utility_transfer_fee}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.yearly_service_charge_estimate &&
                    <li>
                      <strong>Yearly Service Charge Estimate</strong>
                      <span>{props.property_details_facts_data.facts.yearly_service_charge_estimate}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.kharama_fee &&
                    <li>
                      <strong>Kharama Fee</strong>
                      <span>{props.property_details_facts_data.facts.kharama_fee}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.payment_terms &&
                    <li>
                      <strong>Payment Terms</strong>
                      <span>{props.property_details_facts_data.facts.payment_terms}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.security_deposit &&
                    <li>
                      <strong>Security Deposit</strong>
                      <span>{props.property_details_facts_data.facts.security_deposit}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.building_height &&
                    <li>
                      <strong>Building Height</strong>
                      <span>{props.property_details_facts_data.facts.building_height}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.building_type &&
                    <li>
                      <strong>Building Type</strong>
                      <span>{props.property_details_facts_data.facts.building_type}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.building_class &&
                    <li>
                      <strong>Building Class</strong>
                      <span>{props.property_details_facts_data.facts.building_class}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.total_built_up_area &&
                    <li>
                      <strong>Total Built-up Area</strong>
                      <span>{props.property_details_facts_data.facts.total_built_up_area}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.unfinished_ceiling_height &&
                    <li>
                      <strong>Unfinished Ceiling Height</strong>
                      <span>{props.property_details_facts_data.facts.unfinished_ceiling_height}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.typical_floor_size &&
                    <li>
                      <strong>Typical Floor Size</strong>
                      <span>{props.property_details_facts_data.facts.typical_floor_size}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.percent_leased &&
                    <li>
                      <strong>Percent Leased</strong>
                      <span>{props.property_details_facts_data.facts.percent_leased}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.tenancy_type &&
                    <li>
                      <strong>Tenancy Type</strong>
                      <span>{props.property_details_facts_data.facts.tenancy_type}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.permit &&
                    <li>
                      <strong>Permit</strong>
                      <span>{props.property_details_facts_data.facts.permit}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.zone_lifestyle &&
                    <li>
                      <strong>Zone Lifestyle</strong>
                      <span>{props.property_details_facts_data.facts.zone_lifestyle}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.land_status &&
                    <li>
                      <strong>Land Status</strong>
                      <span>{props.property_details_facts_data.facts.land_status}</span>
                    </li>
                  }
                  {props.property_details_facts_data.facts.lease_expiry  &&
                    <li>
                      <strong>Lease Expiry</strong>
                      <span>{convertDate(props.property_details_facts_data.facts.lease_expiry)}</span>
                    </li>
                  }
                  

                </>
              }

            </ul>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

export default PropertyFacts